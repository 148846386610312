import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { ConfigurationService } from '../../../service/configuration/configuration.service';
import { AirFamiliesService } from '../../../service/air-families.service';
import { UppViewNames } from '../../../service/model';
import { getAllAvailableAirFamilies, setAllAvailableAirFamilies, setNotificationData } from './air-families-action';
import { AirFamilySearchRequest } from '../../../families/air/model/air-family-request';
@Injectable()
export class AirFamilyEffects {
  readonly UPP_PARTITIONS_A = 'UPP_PARTITIONS_A';

  constructor(
    private readonly actions$: Actions,
    private readonly airFamiliesService: AirFamiliesService,
    private readonly configurationService: ConfigurationService
  ) {}

  getAllAvailableAirFamilies$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllAvailableAirFamilies),
      mergeMap((action) => {
        const request = this.buildRequest(action);

        return this.airFamiliesService.search(request).pipe(
          switchMap((response) => [setAllAvailableAirFamilies({ airFamilies: response.carriersFamilies })]),
          catchError((error) => 
            // Inform the user for the error fetching air families
             of(setNotificationData({ notification: { error: [error.message] }, viewMode: UppViewNames.SEARCH }))
          )
        );
      })
    )
  );

  private buildRequest(action: { payload: string }): AirFamilySearchRequest {
    const partitionEnabled = this.configurationService.getParameter(this.UPP_PARTITIONS_A);
    const userData = Object.values(action).slice(0, -1).join('');

    return {
      version: '2.0',
      carriersFamily: partitionEnabled ? { partitionId: userData } : { organization: userData }
    };
  }
}
