import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FamiliesSearchComponent } from './families-search/families-search.component';
import { FamiliesDisplayComponent } from './families-display/families-display.component';
import { FamiliesCreateComponent } from './families-create/families-create.component';
import { FamiliesModifyComponent } from './families-modify/families-modify.component';
import { CanDeactivateFamilyCreateGuard } from '../core/util/can-deactivate-family-create.guard';
import { CanDeactivateFamilySearchGuard } from '../core/util/can-deactivate-family-search.guard';
import { CanDeactivateFamilyModifyGuard } from '../core/util/can-deactivate-family-modify.guard';
import { CanManageFamilyGuard } from '../core/util/routing-guards/families/can-manage-family-guard';
import { CanViewFamilyGuard } from '../core/util/routing-guards/families/can-view-family-guard';
import { UppComponentNames, UppViewNames } from '../service/model';
import { LoggedInGuard } from '../core/util/routing-guards/logged-in.guard';
import { AirFamiliesSearchComponent } from './air/air-families-search/air-families-search.component';
import { activationParamGuard } from '../core/util/routing-guards/activation-param-guard';
import { AirFamilyFormComponent } from './air/air-family-form/air-family-form.component';

const familyRoutes: Routes = [
  {
    path: UppComponentNames.FAMILIES,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'air',
        canActivateChild: [activationParamGuard],
        children: [
          {
            path: 'search',
            component: AirFamiliesSearchComponent
          },
          {
            path: 'display',
            component: AirFamilyFormComponent,
            data: { viewMode: UppViewNames.DISPLAY }
          },
          {
            path: 'modify',
            component: AirFamilyFormComponent,
            data: { viewMode: UppViewNames.MODIFY }
          },
          {
            path: 'create',
            component: AirFamilyFormComponent,
            data: { viewMode: UppViewNames.CREATE }
          },
          { path: '', redirectTo: 'search', pathMatch: 'full' },
          { path: '**', redirectTo: 'search', pathMatch: 'full' }
        ]
      },
      {
        path: 'hotel',
        children: [
          {
            path: 'search',
            component: FamiliesSearchComponent,
            canDeactivate: [CanDeactivateFamilySearchGuard],
            canActivate: [CanViewFamilyGuard]
          },
          { path: 'display', component: FamiliesDisplayComponent, canActivate: [CanViewFamilyGuard] },
          { path: 'display/:id', component: FamiliesDisplayComponent, canActivate: [CanViewFamilyGuard] },
          {
            path: 'modify',
            component: FamiliesModifyComponent,
            canDeactivate: [CanDeactivateFamilyModifyGuard],
            canActivate: [CanManageFamilyGuard]
          },
          {
            path: 'modify/:id',
            component: FamiliesModifyComponent,
            canDeactivate: [CanDeactivateFamilyModifyGuard],
            canActivate: [CanManageFamilyGuard]
          },
          {
            path: 'create',
            component: FamiliesCreateComponent,
            canDeactivate: [CanDeactivateFamilyCreateGuard],
            canActivate: [CanManageFamilyGuard]
          },
          { path: '', redirectTo: 'search', pathMatch: 'full' },
          { path: '**', redirectTo: 'search', pathMatch: 'full' }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(familyRoutes)],
  exports: [RouterModule]
})
export class FamiliesRoutingModule {}
