import { Component, OnInit, ViewChild } from '@angular/core';
import { HotelRuleSearchCriteriaUi } from '../model/hotel-rule-search-criteria';
import { HotelRuleSearchRequest, HotelRuleDeleteRequest, convertToHotelSearchCriteria, HotelRuleUi } from '../model';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { NavigationService } from '../../service/core/navigation.service';
import {
  SearchHotelRuleAction,
  ActivateHotelRuleDisplayAction,
  DeleteHotelRuleDisplayNotificationAction,
  ClearHotelRulesAction,
  DeleteHotelRuleSearchNotificationAction,
  DeleteHotelRuleAction,
  StartHotelModificationAction
} from '../../store/hotel/hotels-action';
import { Observable, Subscription } from 'rxjs';
import { INCLUDE_ACTION } from '../model';
import { UppNotification } from '../../model/notification';
import {
  selectHotelRuleSearchSending,
  selectHotelRules,
  selectHotelRuleSearchNotification,
  selectHotelRuleDeleteSending
} from '../../store/hotel/hotels-selector';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { HotelsSearchCriteriaComponent } from './hotels-search-criteria/hotels-search-criteria.component';
import { UserDetailsService } from '../../service/user-details.service';
import { UppComponentNames } from '../../service/model';
import {
  DisplayableField,
  EntryType
} from '../../base/search/basic-search-result-table/basic-search-result-table.component';
import { PERMISSION_MANAGE_HOTEL } from '../../service/user-permissions.service';
import { ConfigurationService } from '../../service/configuration/configuration.service';
import { FeatureFlags } from '../../core/util/resources';

const displayableFields: DisplayableField[] = [
  {
    value: 'pointOfSaleNames',
    label: 'POS'
  },
  {
    value: 'destinationNames',
    label: 'Market'
  },
  {
    value: 'actionType',
    label: 'Action type'
  },
  {
    value: 'description',
    label: 'Description'
  },
  {
    value: 'ruleStatusActive',
    label: 'Rule Status'
  }
];

const displayableFieldsV2: DisplayableField[] = [
  {
    value: 'pointOfSaleNames',
    label: 'Point of sale'
  },
  {
    value: 'destinationNames',
    label: 'Markets'
  },
  {
    value: 'ruleStatusActive',
    label: 'Rule status',
    isSortable: true
  },
  {
    value: 'description',
    label: 'Description'
  }
];

@Component({
  selector: 'ama-ng-upp-hotels-search',
  templateUrl: './hotels-search.component.html'
})
export class HotelsSearchComponent implements OnInit {
  @ViewChild(HotelsSearchCriteriaComponent)
  searchCriteriaComponent!: HotelsSearchCriteriaComponent;

  hotelRuleSearchSending$: Observable<boolean>;
  hotelRuleDeleteSending$: Observable<boolean | undefined>;
  hotelRules$: Observable<HotelRuleUi[]>;
  notification$: Observable<UppNotification | undefined>;

  mainMessages: NotificationMessages;

  managePermission = PERMISSION_MANAGE_HOTEL;
  displayableFields: DisplayableField[] = displayableFields;

  subscription: Subscription = new Subscription();

  constructor(
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly userDetailsService: UserDetailsService,
    private readonly configurationService: ConfigurationService
  ) {
    this.hotelRuleSearchSending$ = this.store.pipe(select(selectHotelRuleSearchSending));
    this.hotelRuleDeleteSending$ = this.store.pipe(select(selectHotelRuleDeleteSending));
    this.hotelRules$ = this.store.pipe(select(selectHotelRules));
    this.notification$ = this.store.pipe(select(selectHotelRuleSearchNotification));
    this.mainMessages = {
      error: $localize`:@@upp.global.text.mainErrorText:The request failed due to the following errors:`,
      warning: $localize`:@@upp.global.text.mainWarningText:Warnings were generated during the search process:`,
      success: $localize`:@@upp.global.text.mainSuccessText:The search was successful.`
    };
  }

  ngOnInit(): void {
    this.navigationService.setSelectedMenuTitle($localize`:@@upp.hotels.search.navigationTitle:Hotel Rule Search`);
    this.navigationService.activateHotels();
    this.navigationService.enableNavigation();
    this.subscription?.add(
      this.configurationService.getParameter$(FeatureFlags.hotelsV2).subscribe((hotelsV2) => {
        this.displayableFields = hotelsV2 ? displayableFieldsV2 : displayableFields;
      })
    );
  }

  searchHotelRules(searchCriteria: HotelRuleSearchCriteriaUi) {
    const request = this.createHotelRulesSearchRequest(searchCriteria);
    request.rule = this.userDetailsService.assignEntity(request.rule);
    this.store.dispatch(new SearchHotelRuleAction({ request }));
  }

  createHotelRulesSearchRequest(searchCriteria: HotelRuleSearchCriteriaUi): HotelRuleSearchRequest {
    return {
      version: '1.0',
      rule: convertToHotelSearchCriteria(searchCriteria)
    };
  }

  closeNotification() {
    this.store.dispatch(new DeleteHotelRuleSearchNotificationAction({}));
  }

  clearHotelRules() {
    this.store.dispatch(new ClearHotelRulesAction({}));
    this.router.navigate(['hotels/search']);
  }

  showHotelRule(id: string) {
    this.store.dispatch(new ActivateHotelRuleDisplayAction({ id: undefined }));
    this.store.dispatch(new DeleteHotelRuleDisplayNotificationAction({}));
    this.router.navigate(['hotels/display/' + id]); // NOSONAR
  }

  modifyHotelRule(hotelRuleId: string) {
    this.store.dispatch(new StartHotelModificationAction({ id: hotelRuleId }));
    this.router.navigate(['hotels/modify/']);
  }

  storeUnsavedHotelSearch() {
    this.searchCriteriaComponent.storeUnsavedHotelSearch();
  }

  deleteHotelRule(request: HotelRuleDeleteRequest) {
    this.store.dispatch(new DeleteHotelRuleAction({ request }));
  }

  getSourceComponent(): UppComponentNames {
    return UppComponentNames.HOTELS;
  }

  getEntryType(): EntryType {
    return EntryType.RULE;
  }

  recordToSearchResult(hotelRecord: HotelRuleUi): any {
    return {
      id: hotelRecord.id,
      name: hotelRecord.name,
      description: hotelRecord.description ? hotelRecord.description : '',
      organization: hotelRecord.organization,
      version: hotelRecord.version,
      pointOfSaleNames: this.getPosOrMarketNames(hotelRecord.hotelApplicability?.pointOfSaleNames),
      destinationNames: this.getPosOrMarketNames(hotelRecord.hotelApplicability?.destinationNames),
      actionType: calculateActionType(hotelRecord),
      ruleStatusActive: hotelRecord.active
    };
  }

  entryToUpdateCreator(
    id: string,
    name: string,
    organization: string,
    version: string,
    ruleVersion: number
  ): HotelRuleDeleteRequest {
    return {
      version,
      rule: {
        id,
        name,
        version: ruleVersion
      }
    };
  }

  entryToUpdateNameResolver(entryToUpdate: HotelRuleDeleteRequest) {
    return entryToUpdate.rule.name;
  }

  getPosOrMarketNames(names?: string[]): string {
    if (names === undefined || names.length === 0) {
      return '';
    }
    return names.toString();
  }
}

const calculateActionType = (rule: HotelRuleUi): string => {
  if (!rule || rule.action === undefined) {
    return '';
  }
  if (rule.action === INCLUDE_ACTION) {
    return $localize`:@@upp.hotels.search.table.include:Incl.`;
  } else if (rule.lightExclude) {
    return $localize`:@@upp.hotels.search.table.lightExclude:Light Excl.`;
  }
  return $localize`:@@upp.hotels.search.table.exclude:Excl.`;
};
