import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Activatable } from '../../../model/activatable';
import { filter, finalize, Observable, startWith, Subscription } from 'rxjs';
import { ConfigurationService } from '../../../service/configuration/configuration.service';
import { FeatureFlags } from '../../../core/util/resources';
import { FormElements, FormKey, searchNotificationMessages } from '../../../model/common-elements';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { saveKeyValue } from '../../../store/common/common-actions';
import { selectKeyValuePairs } from '../../../store/common/common-selectors';
import { UppComponentNames, UppViewNames, ViewMode } from '../../../service/model';
import {
  NAME_WITH_SPACES_VALIDATION_MESSAGES,
  TWO_SYMBOLS_REQUIRED
} from '../../../service/model/common/validation-messages';
import { alphanumericSpaceMax30, twoAlphaNumericRegex } from '../../../service/model/common/validators';
import {
  DisplayableField,
  EntryType,
  SearchResult
} from '../../../base/search/basic-search-result-table/basic-search-result-table.component';
import { AirFamily } from '../model/air-family';
import { AirFamilyDeleteRequest, AirFamilySearchRequest } from '../model/air-family-request';
import { PERMISSION_MANAGE_FAMILY } from '../../../service/user-permissions.service';
import { AirFamiliesService } from '../../../service/air-families.service';
import {
  airFamilyDeleted,
  airFamilyNotificationClosed,
  clearAirFamilyNamesSelection,
  saveAirFamilies,
  saveAirFamily,
  setNotificationData
} from '../../../store/families/air/air-families-action';
import {
  selectAirFamilies,
  selectAirFamily,
  selectAirFamilyNotification,
  selectSelectedAirFamilies
} from '../../../store/families/air/air-families-selector';
import { selectNavigation } from '../../../store/navigation/navigation-selector';
import { LookupOptions } from '../../../core/util/lookup-options';
import { Router } from '@angular/router';
import { RouteURL } from '../../../model/route-url';
import { UserDetailsService } from '../../../service/user-details.service';
import { UppNotification, UppNotificationStatus } from '../../../model/notification';
import { AirFamilySearchResponse } from '../model/air-family-response';
import { NotificationService } from '../../../service/notification.service';

export interface AirFamiliesSearchFormValue {
  name?: string;
  carrierCodes?: string[];
}

@Component({
  selector: 'app-air-families-search',
  templateUrl: './air-families-search.component.html'
})
export class AirFamiliesSearchComponent extends Activatable implements OnInit, OnDestroy {
  isLoading = false;
  searchNotificationMessages = searchNotificationMessages;

  NAME_WITH_SPACES_VALIDATION_MESSAGES = NAME_WITH_SPACES_VALIDATION_MESSAGES;
  TWO_SYMBOLS_REQUIRED = TWO_SYMBOLS_REQUIRED;
  PERMISSION_MANAGE_FAMILY = PERMISSION_MANAGE_FAMILY;

  UppComponentNames = UppComponentNames;
  UppViewNames = UppViewNames;
  EntryType = EntryType;

  airFamiliesSearchForm!: FormGroup;
  FormElements = FormElements;
  airFamilies$!: Observable<AirFamily[]>;

  selectedAirFamilyNames$: Observable<Set<string>>;
  notification$!: Observable<UppNotification | undefined>;

  displayableFields: DisplayableField[] = [
    {
      value: 'description',
      label: 'Description'
    }
  ];

  lookup = false;
  lookupValues: string[] | undefined;
  lookupOptions: LookupOptions | undefined;

  private readonly subscription = new Subscription();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly airFamiliesService: AirFamiliesService,
    private readonly userDetailsService: UserDetailsService,
    private readonly notificationService: NotificationService,
    private readonly router: Router
  ) {
    super();
    this.selectedAirFamilyNames$ = this.store.pipe(
      select(selectSelectedAirFamilies),
      filter((selectedFamilies): selectedFamilies is Set<string> => !!selectedFamilies),
      startWith(new Set<string>())
    );
  }

  get nameControl(): AbstractControl | null {
    return this.airFamiliesSearchForm.get(FormElements.NAME);
  }

  ngOnInit(): void {
    this.createForm();
    this.patchForm();
    this.getAirFamilies();
    this.notification$ = this.store.select(selectAirFamilyNotification(UppViewNames.SEARCH));

    this.store.dispatch(clearAirFamilyNamesSelection());

    this.subscription.add(
      this.store.pipe(select(selectNavigation)).subscribe((lookupParam) => {
        if (lookupParam) {
          this.lookupOptions = lookupParam;
          this.lookupValues = lookupParam.data;
        }
      })
    );

    this.lookup = !!this.lookupOptions?.lookup;
  }

  ngOnDestroy(): void {
    this.store.dispatch(
      saveKeyValue({
        key: FormKey.AirFamiliesSearch,
        value: this.airFamiliesSearchForm.getRawValue()
      })
    );
    this.subscription.unsubscribe();
  }

  isActivated(): Observable<boolean> {
    return inject(ConfigurationService).getParameter$(FeatureFlags.familiesV2);
  }

  search(): void {
    this.isLoading = true;
    const request = this.prepareSearchRequest(this.airFamiliesSearchForm.getRawValue());

    this.subscription.add(
      this.airFamiliesService
        .search(request)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((response: AirFamilySearchResponse) => {
          this.notificationService.handleResponse(response, UppViewNames.SEARCH);
          if (response.status.state === UppNotificationStatus.SUCCESS) {
            this.store.dispatch(saveAirFamilies({ airFamilies: response.carriersFamilies ?? [] }));
          }
          if (response.status.state === UppNotificationStatus.WARNING) {
            this.store.dispatch(saveAirFamilies({ airFamilies: [] }));
          }
        })
    );
  }

  prepareSearchRequest(formValue: AirFamiliesSearchFormValue): AirFamilySearchRequest {
    const request: AirFamilySearchRequest = {
      version: '1.0',
      carriersFamily: {}
    };

    if (formValue.name) {
      request.carriersFamily.name = formValue.name;
    }

    if (formValue.carrierCodes?.length) {
      request.carriersFamily.includedCarriers = formValue.carrierCodes;
    }

    request.carriersFamily = this.userDetailsService.assignEntity(request.carriersFamily);

    return request;
  }

  clearForm(): void {
    this.airFamiliesSearchForm.reset();
    this.store.dispatch(
      saveKeyValue({
        key: FormKey.AirFamiliesSearch,
        value: null
      })
    );
  }

  recordToSearchResult(airFamily: AirFamily): SearchResult {
    return {
      id: airFamily.id,
      name: airFamily.name,
      description: airFamily.description,
      version: airFamily.version
    };
  }

  entryToUpdateNameResolver(deleteRequest: AirFamilyDeleteRequest): string | undefined {
    return deleteRequest.airFamily.name;
  }

  deleteAirFamily(deleteRequest: AirFamilyDeleteRequest): void {
    this.subscription.add(
      this.airFamiliesService.delete(deleteRequest).subscribe((response) => {
        this.notificationService.handleResponse(response, UppViewNames.SEARCH);
        if (response.status.state === UppNotificationStatus.SUCCESS) {
          this.store.dispatch(airFamilyDeleted({ id: deleteRequest.airFamily.id }));
        }
      })
    );
  }

  clearAirFamilies(): void {
    this.store.dispatch(saveAirFamilies({ airFamilies: [] }));
  }

  closeNotification(): void {
    this.store.dispatch(airFamilyNotificationClosed({ viewMode: UppViewNames.SEARCH }));
  }

  entryToUpdateCreator(
    id: string,
    name: string,
    _organization: string,
    version: string,
    ruleVersion: number
  ): AirFamilyDeleteRequest {
    return {
      version,
      airFamily: {
        id,
        name,
        version: ruleVersion
      }
    };
  }

  showAirFamily(id: string, viewMode: ViewMode): void {
    this.subscription.add(
      this.store.select(selectAirFamily(id)).subscribe((airFamily) => {
        if (airFamily) {
          const airFamilyCopy = { ...airFamily };
          delete airFamilyCopy.statusNotification;

          // Save the selected air family in the store for display and modify view
          this.store.dispatch(
            setNotificationData({
              notification: { warning: [], error: [], success: [] },
              viewMode: UppViewNames.DISPLAY
            })
          );
          this.store.dispatch(saveAirFamily({ selectedAirFamily: airFamilyCopy, viewMode: UppViewNames.MODIFY }));
          this.store.dispatch(saveAirFamily({ selectedAirFamily: airFamilyCopy, viewMode: UppViewNames.DISPLAY }));

          this.store.dispatch(
            saveKeyValue({
              key: `${FormKey.AirFamiliesSearch}`,
              value: null
            })
          );
        }
        // Clear the form to avoid showing the previously saved form data
        this.store.dispatch(
          saveKeyValue({
            key: `${FormKey.AirFamilyForm}-${UppViewNames.MODIFY}`,
            value: null
          })
        );

        this.store.dispatch(
          saveKeyValue({
            key: `${FormKey.AirFamilyForm}-${UppViewNames.DISPLAY}`,
            value: null
          })
        );

        const path = viewMode === UppViewNames.MODIFY ? RouteURL.familyAirModify : RouteURL.familyAirDisplay;
        this.router.navigate([path]);
      })
    );
  }

  private createForm(): void {
    this.airFamiliesSearchForm = this.formBuilder.group({
      [FormElements.NAME]: ['', [Validators.maxLength(30), Validators.pattern(alphanumericSpaceMax30)]],
      [FormElements.CARRIER_CODES]: ['', Validators.pattern(twoAlphaNumericRegex)]
    });
  }

  private patchForm(): void {
    this.subscription.add(
      this.store.select(selectKeyValuePairs).subscribe((kvp) => {
        const savedForm = kvp[FormKey.AirFamiliesSearch];
        if (savedForm) {
          this.airFamiliesSearchForm.patchValue(savedForm);
          this.airFamiliesSearchForm.markAllAsTouched();
        }
      })
    );
  }

  private getAirFamilies(): void {
    this.airFamilies$ = this.store.select(selectAirFamilies);
  }
}
